<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      class="hotjar-ignore"
      :headers="headers"
      do-route
      do-route-filters
      :show-operations="false"
      :resource-limit="1000"
      resource="contents"
      :resource-query="{ query: resourceQuery }"
      :meta-options="{ deleted: false }"
      :locale="locale"
      multiple-select
      show-search-filter
      no-meta-check
      :search-filters="filtersList"
      :buttons="buttons"
      route-base="/utilities/cms/contents"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
    >
      <template #actions>
        <div class="actions">
          <actions
            v-permissions="{
              scopes: ['utilities:cms:delete']
            }"
            :selected-items="selectedItems"
            @delete-requested="handleDelete"
          />
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import Actions from '@components/actions/delete'
import { useMessagesStore } from '@/store/messages'

export default {
  components: {
    Actions
  },
  data() {
    return {
      loadingExport: false,
      selectedItems: [],
      filtersList: [
        {
          name: 'name',
          type: 'input',
          label: this.$t('pages.contents.all.filters.name.label')
        }
      ],
      width: 500
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale'
    }),
    resourceQuery() {
      return {
        deleted: false
      }
    },
    buttons() {
      return ['create']
    },
    translations() {
      return {
        image: this.$t(
          'pages.utilities.cms.contents.edit.form.properties.types.image.label'
        ),
        text: this.$t(
          'pages.utilities.cms.contents.edit.form.properties.types.text.label'
        ),
        transition: this.$t(
          'pages.utilities.cms.contents.edit.form.properties.types.transition.label'
        ),
        video: this.$t(
          'pages.utilities.cms.contents.edit.form.properties.types.video.label'
        )
      }
    },
    headers() {
      return [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !row.type) return '-'
            return this.translations[row.type]
          }
        }
      ]
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    refresh() {
      this.$refs.table.refresh()
    },
    async handleDelete(payload) {
      const confirm = await this.$askToDeleteMany(
        payload,
        this.$t('common.resource.cms_content.plural').toLowerCase()
      )
      if (confirm) this.deleteContent()
    },
    async deleteContent() {
      if (this.selectedItems.length > 1) {
        this.handleBulkDelete(this.selectedItems)
        return
      }
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })

      const selectedItem = this.selectedItems[0]
      try {
        const inst = th.contents()
        await inst.delete(selectedItem.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleBulkDelete(items) {
      const inst = th.contents()

      const operations = items.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.error.action.delete.multiple', {
        resources: this.$t('common.resource.cms_content.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    filterLogger(message, ...args) {
      // console.log(message, ...args) // eslint-disable-line
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.utilities.cms.contents.title')
        })
      })
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
}

.actions > * {
  margin-right: 10px;
}

.popover {
  margin-right: 10px;
}
</style>
